import React from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Table } from 'react-bootstrap';

const data = {
  eng: {
    chapter: 'Chapter',
    trys: 'Trys',
    date: 'Date',
    score: 'Score',
    status: 'Status',
    not_avail: 'Not Available',
    prog: 'Quiz Progress',
  },
  esp: {
    chapter: 'Capítulo',
    trys: 'Trys',
    date: 'Fecha',
    score: 'Puntaje',
    status: 'Estado',
    not_avail: 'No disponible',
    prog: 'Progreso de la prueba',
  },
};

const QuizOverlay = (props) => {
  const { quizData, lang } = props;
  const getContent = () => {
    if (quizData.length === 0) {
      return (
        <tr>
          <td colSpan="5">{data[lang].not_avail}</td>
        </tr>
      );
    }

    return quizData.map((data, index) => {
      const { quiz_title, attempts, updated_at, score } = data;

      return (
        <tr key={`quiz-status-${index}`}>
          <td>{quiz_title.split('-')[1].trim()}</td>
          <td className="text-center">{attempts}</td>
          <td>{updated_at}</td>
          <td>{score}%</td>
          <td className="text-center">
            {score === 100 ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="">
      <div className="dsp-flx flx-row align-items-center">
        <p className="highlight-onhover mb-0 fnt-primary fnt-r-14 fnt-poppins mr-2">{data[lang].prog}</p>
        <FiBarChart2 color="#FC650A" size={16} />
      </div>
      <div className="table-container mt-2">
        <Table responsive="md">
          <thead>
            <tr>
              <th>{data[lang].chapter}</th>
              <th className="text-center">{data[lang].trys}</th>
              <th>{data[lang].date}</th>
              <th>{data[lang].score}</th>
              <th className="text-center">{data[lang].status}</th>
            </tr>
          </thead>
          <tbody>{getContent()}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default QuizOverlay;
