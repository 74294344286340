import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Router } from '@reach/router';
import Header from '../components/header/header';
import MainContainer from '../components/profile/profile';
import Footer from '../components/footer';
import ExpandableDiv from '../components/expandableContainer';
import AdsBanner from '../components/adsBanner';
import AuthStateProvider from '../components/authStateProvider';

const ProfilePage = (props) => {
  const { books, translatedUrl } = props.pageContext;
  const { miniPlayer, language } = useSelector((state) => state);

  return (
    <AuthStateProvider authRequired className="bg-grey" {...props}>
      <Header show={true} translatedUrl={translatedUrl} />
      <ExpandableDiv expand={miniPlayer.show}>
        <MainContainer books={books} />
        <AdsBanner language={language} />
      </ExpandableDiv>
      <Footer variant="post-login-footer" />
    </AuthStateProvider>
  );
};

export default ProfilePage;
