import React from 'react';
import './style.scss';

const ProgressBar = (props) => {
  const { progress } = props;
  let completed = 0;
  let incomplete = 0;
  if (progress <= 50) {
    completed = progress / 50;
    incomplete = 2 - completed;
  } else {
    completed = 1 + (progress - 50) / 50;
    incomplete = 1;
  }

  return (
    <div className="dsp-flx">
      <div className="dsp-flx flx-center flx-1">
        <div className="progress-bar complete" style={{ transform: `scaleX(${completed})` }}></div>
        <div className="progress-bar incomplete" style={{ transform: `scaleX(${incomplete})` }}></div>
      </div>
      <div>
        <p className="mb-0 ml-3 fnt-black fnt-evolventa fnt-r-14">{`${progress}%`}</p>
      </div>
    </div>
  );
};

export default ProgressBar;
