import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BookCard from './bookCard';
import { getQuizDetail } from '../../actions/quizActions';
import './style.scss';

const renderBooks = (books, quizDetails) => {
  return books.map((book, index) => (
    <div key={`profile-books-${index}`}>
      <BookCard book={book} quizData={quizDetails[book.book_id] || []} />
    </div>
  ));
};

const MainComponent = (props) => {
  const { books } = props;
  const [quizDetails, setQuizDetails] = useState({});
  const { auth, language } = useSelector((state) => state);
  const lang = language === 'esp' ? 'es' : 'en';

  useEffect(() => {
    localStorage.removeItem('qrUrl');
    async function getDetails() {
      const response = await getQuizDetail(auth.userId, lang);
      setQuizDetails(response);
    }
    getDetails();
  }, []);

  return <div className="profile-container">{renderBooks(books, quizDetails)}</div>;
};
export default MainComponent;
